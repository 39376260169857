@import "https://fonts.googleapis.com/css2?family=Chewy&family=Inconsolata:wght@200..900&family=Noto+Sans+Mono:wght@100..900&display=swap";
* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.font-mono {
  font-family: Noto Sans Mono;
}

.animate-pulse {
  animation: 1.5s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.animate-current-guessable {
  animation: 1.5s infinite animation-current-guessable;
}

@keyframes animation-current-guessable {
  0% {
    background-color: #a9a9a9;
  }

  50% {
    background-color: #90ee90;
    transform: scale(1.1);
  }

  100% {
    background-color: #a9a9a9;
  }
}

.animate-background-grey-red {
  animation: 2s infinite background-fade-grey-red;
}

.animate-background-grey-red-short {
  animation: .5s background-fade-grey-red;
}

@keyframes background-fade-grey-red {
  0% {
    background-color: #22222280;
  }

  50% {
    background-color: #ff000080;
  }

  100% {
    background-color: #22222280;
  }
}

.animate-color-red {
  animation: 1.5s color-red;
}

@keyframes color-red {
  0% {
  }

  50% {
    color: red;
    font-weight: bold;
  }

  100% {
  }
}

.animate-pulse-color {
  animation: 3s pulse-color;
}

@keyframes pulse-color {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.css.map */
