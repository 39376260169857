@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Inconsolata:wght@200..900&family=Noto+Sans+Mono:wght@100..900&display=swap');

* {
    box-sizing: border-box;
}

body {
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.font-mono {
    font-family: "Noto Sans Mono";
}


/* animation */

.animate-pulse {
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

}

@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }
}

.animate-current-guessable {
    animation: animation-current-guessable 1.5s infinite;
}

@keyframes animation-current-guessable {
    0% {
        background-color: rgb(169, 169, 169);
    }

    50% {
        transform: scale(1.1);
        background-color: rgb(144, 238, 144);
    }

    100% {
        background-color: rgb(169, 169, 169);
    }

}

.animate-background-grey-red {
    animation: background-fade-grey-red 2s infinite;
}

.animate-background-grey-red-short {
    animation: background-fade-grey-red 0.5s;
}

@keyframes background-fade-grey-red {
    0% {
        background-color: rgba(34, 34, 34, 0.5)
    }

    50% {
        background-color: rgba(255, 0, 0, 0.5)
    }

    100% {
        background-color: rgba(34, 34, 34, 0.5)
    }

}

.animate-color-red {
    animation: color-red 1.5s;
}

@keyframes color-red {
    0% {}

    50% {
        color: rgba(255, 0, 0, 1);
        font-weight: bold;
    }

    100% {}

}


.animate-pulse-color {
    animation: pulse-color 3s;
}

@keyframes pulse-color {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}